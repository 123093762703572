<template>
    <div>
        <TitleBar @return="returnBack" @save="save">{{ pageTitle }}</TitleBar>
        <div class="main-container">
            <div class="mobile-preview">
                <CardPreview :companyName="companyName" :companyIntro="companyIntro" :template="templateInfo"></CardPreview>
            </div>
            <div class="setting-panel">
                <div class="info-setting">
                    <div class="info-top">
                        <h2>名片模板编辑</h2>
                    </div>
                    <div class="info-data">
                        <div class="info-item">
                            <label required>模板名称</label>
                            <Input v-model="templateInfo.name" style="width: 256px" placeholder="请输入模板名称" />
                        </div>
                        <div class="info-item">
                            <label>名片背景色</label>
                            <ColorPicker :transfer="true" v-model="templateInfo.backgroundColor" />
                        </div>
                        <div class="info-item">
                            <label>名片字体色</label>
                            <ColorPicker :transfer="true" v-model="templateInfo.foreColor" />
                        </div>
                    </div>
                </div>
                <div class="knowledge-shelf-setting">
                    <div class="ks-top">
                        <h2>匹配知识货架</h2>
                        <Button type="primary" icon="md-add-circle" @click="showAddDialog">添加知识货架</Button>
                    </div>
                    <Table class="mapping-table" :columns="columns" :data="templateInfo.knowledgeShelfList">
                        <template slot-scope="{ row }" slot="coverImg">
                            <img class="cell-image" :src="row.coverImg" />
                        </template>
                        <template slot-scope="{ index }" slot="action">
                            <button @click="removeKnowledgeShelf(index)">
                                <i class="nazaio-iconfont nz-delete" />
                            </button>
                        </template>
                    </Table>
                </div>
            </div>
        </div>
        <Modal v-model="showDialog" width="800" title="选择知识货架" class="knowledge-dialog">
            <div class="knowledge-box">
                <div class="search-bar">
                    <Input
                        v-model="query.name"
                        clearable
                        prefix="ios-search"
                        placeholder="请根据知识货架的名称搜索"
                        style="width: 220px"
                        @on-blur="getList"
                        @keyup.enter.native="getList"
                    />
                </div>
                <Table class="knowledge-table" :columns="columns2" :data="dataList" height="400" @on-selection-change="selectionChange">
                    <template slot-scope="{ row }" slot="coverImg">
                        <img class="cell-image" :src="row.coverImg" />
                    </template>
                </Table>
                <div class="pagination" v-if="totalSize / query.pageSize > 1">
                    <Page
                        @on-change="pageChange"
                        :total="totalSize"
                        :pageSize="query.pageSize"
                        :current="query.pageNum"
                        show-elevator
                        show-total
                    />
                </div>
            </div>
            <div slot="footer">
                <Button type="primary" @click="selectConfirm">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import TitleBar from "./components/TitleBar";
import { getList, getCompanyInfo } from "@/api/knowledge-shelf";
import { get, save } from "@/api/card-template";
import CardPreview from "../components/CardPreview";

export default {
    components: {
        TitleBar,
        CardPreview,
    },
    created() {
        this.templateInfo.id = this.$route.params.id;
        if (this.templateInfo.id) {
            get(this.templateInfo.id).then((response) => {
                this.templateInfo.name = response.name;
                this.templateInfo.backgroundColor = response.backgroundColor;
                this.templateInfo.foreColor = response.foreColor;
                this.templateInfo.knowledgeShelfList = response.knowledgeShelfList || [];
                this.watchData();
            });
        } else {
            this.watchData();
        }
        getCompanyInfo().then((response) => {
            this.companyName = response.companyName;
            this.companyIntro = response.companyIntro;
        });
    },
    beforeRouteLeave(to, from, next) {
        if (this.changed) {
            this.$Modal.confirm({
                title: "确定返回上一页吗？",
                content: "<p>您还没有保存编辑，是否确定返回到上一页？</p>",
                onCancel: () => {
                    next(false);
                },
                onOk: () => {
                    next();
                }
            });
        } else {
            next();
        }
    },
    data() {
        return {
            templateInfo: {
                id: 0,
                name: "",
                backgroundColor: "#fff",
                foreColor: "#000",
                knowledgeShelfList: [],
            },
            changed: false,
            companyName: "",
            companyIntro: "",
            columns: [
                {
                    title: "封面图",
                    slot: "coverImg",
                    // width: 200,
                    align: "center",
                },
                {
                    title: "知识货架名称",
                    key: "name",
                },
                {
                    title: "创建时间",
                    key: "createTime",
                    align: "center",
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    // width: 140,
                },
            ],
            columns2: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "封面图",
                    slot: "coverImg",
                    width: 160,
                    align: "center",
                },
                {
                    title: "知识货架名称",
                    key: "name",
                },
                {
                    title: "创建时间",
                    key: "createTime",
                    width: 200,
                    align: "center",
                },
            ],
            showDialog: false,
            totalSize: 0,
            dataList: [],
            query: {
                name: "",
                bindCardEnabled: 1,
                pageNum: 1,
                pageSize: 10,
            },
            selection: [],
        };
    },
    computed: {
        pageTitle() {
            if (this.templateInfo.id) {
                return "编辑名片模板";
            } else {
                return "新建名片模板";
            }
        },
    },
    methods: {
        showAddDialog() {
            this.knowledgeShelfName = "";
            this.showDialog = true;
            this.selection = [];
            this.query.name = "";
            this.query.pageNum = 1;
            this.query.pageSize = 10;
            this.getList();
        },
        watchData() {
            this.$watch("templateInfo", {
                handler: () => {
                    this.changed = true;
                },
                deep: true,
            });
        },
        returnBack() {
            this.$router.go(-1);
        },
        getList() {
            getList(this.query).then((response) => {
                this.totalSize = response.totalSize;
                this.dataList = response.data;
                this.dataList.forEach((d) => {
                    if (this.templateInfo.knowledgeShelfList.findIndex((k) => k.id === d.id) >= 0) {
                        d._checked = true;
                        d._disabled = true;
                    }
                });
            });
        },
        removeKnowledgeShelf(index) {
            this.$Modal.confirm({
                title: "确定删除匹配的知识货架吗？",
                content: "<p>删除匹配的知识货架后，该名片模板的推荐资料中不再显示该知识货架，是否确认删除该知识货架？</p>",
                onOk: () => {
                    this.templateInfo.knowledgeShelfList.splice(index, 1);
                },
            });
        },
        save() {
            if (!this.templateInfo.name) {
                this.$Message.error("模板名称不能为空！");
                return;
            }
            let data = JSON.parse(JSON.stringify(this.templateInfo));
            let ids = [];
            data.knowledgeShelfList.forEach((item) => ids.push(item.id));
            data.knowledgeShelfIds = ids.join(",");
            delete data.knowledgeShelfList;
            save(data).then((response) => {
                this.templateInfo.id = response;
                this.$Message.success("保存成功！");
                this.$nextTick(() => {
                    this.changed = false;
                });
            });
        },
        pageChange(pageNum) {
            this.query.pageNum = pageNum;
            this.getList();
        },
        selectionChange(selection) {
            this.selection = selection;
        },
        selectConfirm() {
            this.selection.forEach((s) => {
                if (this.templateInfo.knowledgeShelfList.findIndex((k) => k.id === s.id) < 0) {
                    this.templateInfo.knowledgeShelfList.push(s);
                }
            });
            this.showDialog = false;
        },
    },
};
</script>
<style lang="less" scoped>
.main-container {
    display: flex;
}

.mobile-preview {
    width: 415px;
    flex: 0 0 415px;
    // margin-bottom: -20px;
    overflow: hidden;
}

.setting-panel {
    margin-left: 26px;
    flex: 1 1 auto;
    border-radius: 4px;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .info-setting {
        background-color: #fff;
        padding: 16px 20px;
        flex: 0 0 auto;
    }

    .info-top {
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 16px;
            color: #0a2a4c;
            margin: 0;
            height: 24px;
            line-height: 24px;
        }
    }

    .info-item {
        display: flex;
        label {
            font-size: 14px;
            color: #0a2a4c;
            height: 32px;
            line-height: 32px;
            margin-right: 16px;

            &[required]:after {
                content: "*";
                color: #ff0000;
                margin-left: 6px;
            }
        }
    }

    .info-data {
        display: flex;
        padding-left: 22px;
        margin-top: 20px;

        .info-item {
            margin-left: 59px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .knowledge-shelf-setting {
        background-color: #fff;
        margin-top: 12px;
        flex: 1 1 auto;
        overflow-y: auto;
        padding: 16px 20px;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }

        .ks-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

            h2 {
                font-size: 16px;
                color: #0a2a4c;
                margin: 0;
                height: 32px;
                line-height: 32px;
            }
        }
    }
}

.mapping-table {
    button {
        background-color: transparent;
        border: none;
        outline: none;
        color: #395069;
        cursor: pointer;

        &:hover {
            color: @fc-main-color;
        }
    }
}

.knowledge-table {
    margin-top: 20px;
}

.pagination {
    margin-top: 24px;
    text-align: right;
}

.cell-image {
    width: 120px;
    height: 80px;
    margin: 16px auto;
    display: block;
    object-fit: cover;
}
</style>